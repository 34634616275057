<template>
  <div>
    <TopMenuSubheadline :title="title"/>
        <div class="app-content-box bg-gray">
      <div class="image-container">
        <img src="../assets/images/barcode-pic-example.jpg" alt="">
      </div>
      <div class="container">
        <div class="row mt-4">
          <div class="col-5">
            <img src="../assets/images/profile-book-cover.jpg" alt="" class="w-100">
          </div>
          <div class="col-7 pl-0">
            <p class="book-side-author-top">J.R.R. Tolkien</p>
            <h3 class="book-side-title-big">Władca Pierścieni Dwie wieże</h3>
          </div>
        </div>
        <router-link to="/dodaj-ksiazke"><a href="#" class="btn-black-full w-100 mt-3">Dodaj książkę</a></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'ScanResult',
  components: {
    TopMenuSubheadline
  },
  data () {
    return {
      title: 'Zeskanuj książkę'
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    padding-top: 60px;
  }
</style>
